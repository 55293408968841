import { useState,useRef, useReducer } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const initialState = {
  name: '',
  email: '',
  message: '',
  contact_number: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'user_name':
      return { ...state, user_name: action.value };
    case 'user_email':
      return { ...state, user_email: action.value };
    case 'message':
      return { ...state, message: action.value };
    case 'contact_number':
      return { ...state, contact_number: action.value };

    default:
      throw new Error();
  }
}

export const ContactUs = () => {
  const form = useRef();
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [, setShowFormErr] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState({ title: '', paragraph: '' });
  const [showCaptcha, setShowCaptcha] = useState(false);
  const { user_name, user_email, message, contact_number } = formState;

  const submitFormAndShowCaptcha = (e) => {
    e.preventDefault();
    setShowCaptcha(true);
  };

  const sendEmail = (captchaValue) => {
    if (user_name === '' || user_email === '' || message === '') {
      setShowFormErr(true);
      return;
    }

    const params = {
      ...formState,
      'g-recaptcha-response': captchaValue,
    };

    setFormSubmitted({ title: 'Sending message...', paragraph: '' });
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      params,
      process.env.REACT_APP_EMAIL_PUBLIC_KEY,
    )
      .then(({ status }) => {
        if (status === 200) {
          window.location.reload()
        } else {
          setFormSubmitted({ title: 'Unexpected status code returned from EmailJS, try again later', paragraph: 'Please contact Mike either by phone or email.' });
        }
      }, (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setFormSubmitted({ title: 'Error sending message, try again later', paragraph: 'Please contact Mike either by phone or email.' });
      });
  };

  return formSubmitted.title === '' ? (
    <>
      <div className="container contact-div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
          {!showCaptcha ? (
            <form ref={form} onSubmit={submitFormAndShowCaptcha}>
              <div className="form-group">
                <label for="contact-form-name">Enter Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact-form-name"
                  name="user_name"
                  value={user_name}
                  onChange={(e) => dispatch({ type: 'user_name', value: e.target.value })}
                  required
                  placeholder="Enter Your Name"
                />
              </div>
              <div className="form-group pt-3">
                <label for="contact-form-number">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact-form-number"
                  name="contact_number"
                  value={contact_number}
                  onChange={(e) => dispatch({ type: 'contact_number', value: e.target.value })}
                  placeholder="+40-"
                />
              </div>
              <div className="form-group pt-3">
                <label for="contact-form-email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="contact-form-email"
                  name="user_email"
                  value={user_email}
                  onChange={(e) => dispatch({ type: 'user_email', value: e.target.value })}
                  required
                  placeholder="name@example.com"
                />
              </div>

              <div className="form-group pt-3">
                <label for="contact-form-message">
                  Type your Message Here
                </label>
                <textarea
                  className="form-control"
                  id="contact-form-message"
                  rows="3"
                  name="message"
                  value={message}
                  onChange={(e) => dispatch({ type: 'message', value: e.target.value })}
                  required
                  placeholder="Message"
                ></textarea>
              </div>

              <div className="col-12 pt-3">
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                >
                  Submit form
                </button>
              </div>
            </form>
          ) : (
            <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={sendEmail}
          />
          )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="flex flex-col items-center">
      <h3 className="text-lato text-2xl font-light text-white">{formSubmitted.title}</h3>
      <p>{formSubmitted.paragraph}</p>
    </div>
  );
}
export default ContactUs;