import React from "react";
import Card from "./Card";
import img1 from "../src/Pictures/img1.png";
import img2 from "../src/Pictures/img2.png";
import img4 from "../src/Pictures/img4.png";
import img5 from "../src/Pictures/img5.png";
import img6 from "../src/Pictures/img6.png";

function Service() {
  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Our Services</h1>
      </div>

      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              <Card
                title="DevOps Consulting"
                imgsrc={img2}
                text="Help you automate development, testing and deployment of your applications at any stage of your application life cycle." />
              <Card
                title="Cloud Consulting"
                imgsrc={img5}
                text="Cloud adoption strategy to plan, prioritize, and schedule IT infrastructure migration." />
              <Card
                title="Python Development"
                imgsrc={img4} text="Design and development of cloud apps including IT infrastructure design and CI/CD pipelines design." />
              <Card
                title="Amazon Web Services Migrations"
                imgsrc={img1}
                text="Legacy infrastructure migration to the cloud via replatforming, refactoring." />
              <Card
                title="Kafka Consultant"
                imgsrc={img4}
                text="We help your business improve real-time data flow and processing with Apache Kafka." />
              <Card
                title="Site Reliability Engineering"
                imgsrc={img6}
                text="Setup of monitoring tools to measure cloud app/DWH performance, load, etc." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
